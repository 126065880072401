.square {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  border: 3px solid;
  border-image: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6) 1;
  position: absolute;
}

.l1 {
  width: 98px;
  height: 3px;
  background: linear-gradient(-135deg, #697ff0, #ffec99, #ccb0c4);
  rotate: 90deg;
  margin-top: 46px;
  margin-left: -2px;
}

.l2 {
  width: 3px;
  height: 98px;
  background: linear-gradient(-135deg, #697ff0, #ffec99, #ccb0c4);
  rotate: 90deg;
  margin-top: -50px;
  margin-left: 46px;
}

.cl1 {
  width: 69px;
  height: 3px;
  background: linear-gradient(-135deg, #ccb0c4, #ffec99, #ccb0c4);
  rotate: -45deg;
  margin-top: -76px;
  margin-left: -11px;
}

.cl2 {
  width: 68px;
  height: 3px;
  background: linear-gradient(-135deg, #697ff0, #ffec99, #697ff0);
  rotate: 135deg;
  margin-top: 46.5px;
  margin-left: 38px;
}
