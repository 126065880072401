.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  position: relative;
}

.square {
  width: 100px;
  height: 100px;
  border-radius: 25px; /* Rounded corners for the first square */
  border: 3px solid; /* Thinner border */
  border-image: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6) 1;
  position: absolute;
}

.square-two {
  width: 80px; /* Smaller square */
  height: 80px;
  border-radius: 20px; /* Rounded corners */
  border: 3px solid;
  border-image: linear-gradient(-135deg, #ffec99, #9f7aea, #3b82f6) 1; /* Flipped gradient */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square-three {
  width: 60px; /* Smaller than square-two */
  height: 60px;
  border-radius: 15px; /* Rounded corners */
  border: 3px solid;
  border-image: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6) 1; /* Reverted gradient */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square-four {
  width: 40px; /* Smaller than square-three */
  height: 40px;
  border-radius: 10px; /* Rounded corners */
  border: 3px solid;
  border-image: linear-gradient(-135deg, #ffec99, #9f7aea, #3b82f6) 1; /* Flipped gradient */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square-five {
  width: 20px; /* Smallest square */
  height: 20px;
  border-radius: 5px; /* Rounded corners */
  border: 3px solid;
  border-image: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6) 1; /* Reverted gradient */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
