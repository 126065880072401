.pSquare {
  width: 100px;
  height: 100px;
  border-radius: 15px; /* Slightly rounded corners for elegance */
  border: 3px solid;
  border-image: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6) 1;
  position: absolute;
}

.concentric-square {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 10px; /* Matching rounded corners */
  border: 3px solid;
  border-image: linear-gradient(135deg, #3b82f6, #9f7aea, #ffec99) 1;
}

.diagonal-line {
  position: absolute;
  width: 138px;
  height: 2px;
  background: linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6);
  transform: rotate(45deg);
}

.diagonal-line:nth-child(2) {
  transform: rotate(-45deg);
}

.inner-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid;
  border-image: linear-gradient(135deg, #9f7aea, #ffec99) 1;
}

.star {
  position: absolute;
  width: 40px;
  height: 40px;
  clip-path: polygon(
    50% 0%,
    61.8% 38.2%,
    100% 50%,
    61.8% 61.8%,
    50% 100%,
    38.2% 61.8%,
    0% 50%,
    38.2% 38.2%
  );
  background: linear-gradient(135deg, #ffec99, #3b82f6);
}
